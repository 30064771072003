ul {
  list-style-type: none;
  padding-left: 5px;
}

.custom-li{
  padding: 0.7rem !important;
  border-radius: 3px;
  cursor: pointer;
}

ul i {
  margin-right: 4px;
  color: #175C7D;
}
.column {
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
  border: 1px solid #eee;
}

.selected {
  color: #ffffff;
  background: #175c7d;
  font-weight: bold;
}

.selected i {
  color: #ffffff;
}