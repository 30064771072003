html {
    font-size: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
}

.body {
  font-family: '', SourceSansPro-Regular;
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  overflow-x: hidden;
  line-height: 20px;
  background:  #E5E5E5!important;
  min-height: 100vh;
}

.modal {
  overflow-x: hidden  !important;
  overflow-y: auto  !important;
}



a,
a:active,
a:hover,
a:focus,
a:visited{
	text-decoration:none;
	outline:none;
	color:inherit;
}


button:focus{
	outline:none;
}


article,
figure,
footer,
header,
nav,
section{
	display:block
}

select:focus {
    outline-offset:0;
}

/* Internet Explorer 10 in Windows 8 and Windows Phone 8 Bug fix */
@-webkit-viewport {
	width: device-width;
}
@-moz-viewport {
	width: device-width;
}
@-ms-viewport {
	width: device-width;
}
@-o-viewport {
	width: device-width;
}
@viewport {
	width: device-width;
}





a:focus, a:hover {
    	text-decoration:none !important;
	}


.navbar {
    /*position: relative;*/
    min-height: 50px;
    margin-bottom: 20px;
    border: none;
}

.navbar-dark .navbar-toggler {
  /*border: 2px solid #FFFFFF;*/
  color: #ffffff !important;
  background:#07A0AB !important;
}

.navbar-toggle .icon-bar {
    background: #fff !important;
}

.nav > li > a:hover, .nav > li > a:focus {
    background-color: transparent;
}

.header-body {
  height: calc(100vh - 80px);
}

#form .input-group input[class="form-control"] {
  border-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 17px;
  color:#222;
  padding:7px 12px;
  border-right-width:0;
}

#form .input-group select[class="form-control"] {
  border-radius:50px;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  font-size:17px;
  width:100px;
  color:#222;
  border-left-width:0;
}

.form-control:focus {
    border:1px solid #ccc ;
    outline:0;
    -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(254, 86, 33, .6) ;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 2px rgba(0, 0, 0, .6);
}

.form-group1 .form-control:focus {
    border:none!important;
    outline:0;
    -webkit-box-shadow:none !important;
    box-shadow: none !important;
    border-bottom:1px solid brown !important;
}


.btn-block{
  display: block;
  width: 100%;
}




::-webkit-scrollbar{
	height: 7px;
	width: 7px;
}
::-webkit-scrollbar-track{
	border-radius: 50px;
}
::-webkit-scrollbar-thumb{
	border-radius: 1px;
	background-color: #a7a7a7;
}




/*=================================================================
  Dashboard Css
==================================================================*/
.leftbar{
  position: fixed;
  padding-top: 16px;
  left: 0%;
  top: 0%;
  background-color: #ffffff;
  width: 16.7%;
  height: 100%;
  transition: all .6s ease;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2rem;
}

.leftbar::-webkit-scrollbar {
    width: 0px;
}
.leftbar i {
    float: left;
    padding: 4px;
}

.__dashboard {
  background-color: transparent;
  padding: 15px 30px;
  font-size: 15px;
  color: #787878;
  line-height: 1.5;
  /* margin-top:10px; */
  display: block;
}

.__dashboard:hover {
  background-color: #F5FEFF;
  color: #0C9EA9;
}

.activedashboard{
  background-color: #F5FEFF !important;
  color:#0C9EA9 !important;
  padding: 15px 30px;
  line-height: 1.5;
  font-family: SourceSansPro-Bold;
  display: block;
}

.__leftbarimg{
  width: 19px;
}

.__dashlogoimg{
  padding: 20px 25px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20%;
}

.__sidimg{
  width: 178px;
  margin-top: -7px;
}

#openSidebar{
  display: none;
}
.maianer {
    padding: 10px 55px;
}
.biger{
  width: 35% ;
  transform: translate(0px) !important;
  z-index:1  !important;

}
img#closebtn {
    position: absolute;
    top: 3%;
    color: indianred;
    font-size: 21px;
    display: none;
    cursor: pointer;
    right: 5%;
    width: 15px;
}


/*hover on dropdown and drop*/
ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
    border-radius: 0;
}
/*hover on dropdown and drop end*/

.__dasovp{
  font-size: 20px;
  letter-spacing: -0.713086px;
  color: #000000;
  margin-top: 16px;
}

.__dashnotif{
  color:#29AAB4 !important;
  font-size: 26px;
  margin-top: 4px;
}

.__dashnotif sup{
  background: #FF0000;
  border: 1px solid #FAFAFA;
  color: #ffffff;
  border-radius: 50%;
  font-size: 8px;
  padding: 1px 4px;
  margin-left: -9px;
  top: -1.9em;
}

.__house {
  padding:0 25px;
}
.__house1 {
  background: #ffffff;
  padding: 28px 44px;
  border: 1px solid #e6e9ec;
}



.__dashnavbar{
  background-color: transparent;
  margin-bottom: 0;
  border-radius: 0;
}

.__droprof{
  background: #F3F3F3 !important;
    border-radius: 100px;
    padding: 6px 15px !important;
    letter-spacing: 0.365px;
    color: #001B36 !important;
    margin-top: 13px;
}

.__dashimg{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}



.__line:before {
    content: "";
    width: 2px;
    height: 33px;
    background-color: #99a8cc;
    right: 0;
    top: 11px;
    position: absolute;
}


/*=================================================================
  content css
==================================================================*/
.__spacet2p{
  margin-top:2%;
}

.__spacet3p{
  margin-top:3%;
}

.__spacet4p{
  margin-top:4%;
}

.__spacet5p{
  margin-top:5%;
}

.__spacet7p{
  margin-top:7%;
}

.__spacet10p{
  margin-top:10%;
}

.__spacet15p{
  margin-top:15%;
}

.__spacet20p{
  margin-top:20%;
}


.__navbar-brand1{
  color: #252733 !important;
}

/*overview css*/

.__addrp{
  margin: 0 0 0 54px;
}

.__adropdown-menu{
  left: -23px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-align: center;
  margin-top: 4rem;
}

.__adropdown-menu>li>a {
  background: #07A0AB;
    border-radius: 10px;
    color: #ffffff !important;
    margin-bottom: 10px;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    cursor: pointer;
}

.__subbtn{
  background: #07A0AB;
  border: 2px solid #FFFFFF;
  border-radius: 50px;
  color: #ffffff !important;
  padding: 7px 20px;
  margin-top: 30px;
}

.__dropdown-menu{
  background: #FAFAFA;
    box-shadow: 0px 4px 15px rgba(12, 158, 169, 0.15);
    border-radius: 10px !important;
    text-align: center;
    min-width: 113px;
}

.__dropdown-menu>li>a{
  letter-spacing: 0.365px;
  color: #07A0AB !important;
}

.__dascard{
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  padding: 20px 0 25px;
  transition: all 0.4s ease-in;
  margin-top: 10px;
}

.__dascard:hover{
  border: 1px solid  #0C9EA9;
  box-shadow: 0px 0px 15px rgba(127, 194, 199, 0.6);
}

.__dasp{
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #9FA2B4;
  font-family: SourceSansPro-Bold;
  transition: all 0.4s ease-in;
}

.__dasp1{
  font-size: 30px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
  font-family: SourceSansPro-Bold;
  transition: all 0.4s ease-in;
  margin-top: 20px;
}

.__dascard:hover .__dasp{
  color: #0C9EA9;
}

.__dascard:hover .__dasp1{
  color: #0C9EA9;
}

.__btcard{
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 10px;
  margin-top: 10px;
}

.__btcardin{
  padding:10px;
}

.__btcardin1 {
  padding: 0px 35px;
}

.__subp{
  letter-spacing: -0.713086px;
  color: #AFAFAF;
  font-family: SourceSansPro-Regular;
}

.__subp1{
  letter-spacing: -0.713086px;
  color: #AFAFAF;
  text-align:center;
  font-family: SourceSansPro-Regular;
}

.__subp2{
  letter-spacing: -0.713086px;
  color: #AFAFAF;
  font-family: SourceSansPro-Regular;
  text-align:right;
}

.__subp2 span{
  border-radius: 10px;
  padding: 3px 12px;
}

.__subpen{
  color: #464646;
  background: #F0F0F0;
  border-radius: 10px;
  font-family: SourceSansPro-Regular;
  padding: 2px 15px;
}

.__subit{
  font-style: italic;
  font-family: SourceSansPro-Regular;
}

.__btp{
  font-size: 16px;
  line-height: 132%;
  color: #333333;
  font-family: SourceSansPro-Bold;
  margin-top: 10px;
}

.__btform{
  background: #F8F8F8;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: none;
}

.__btform:focus{
  background: #F8F8F8;
  border-radius: 5px;
  border: 1px solid transparent !important;
  box-shadow: none !important;
}




 /* Default table styles for this demo */
.__table td span {
  background: #2f31940d;
  color: #000000;
  display: none;
  font-size: 13px;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
}


/* Simple CSS for flexbox table on mobile */
@media(max-width: 500px) {

  .__table{
    margin-bottom: 50px;
  }
  .__table thead {
      left: -9999px;
      position: absolute;
      visibility: hidden;
  }
  .__table tr {
      border-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 35px;
  }
  .__table td {
      border: solid 1px rgba(74, 73, 73, 0.08);
      margin: 0 -1px -1px 0;
      padding-top: 30px !important;
      position: relative;
      width: 50%;
      white-space:unset !important;
      overflow-wrap: break-word;
  }
  .__table td span {
      display: block;
  }

  .table-responsive {
    border: 0px solid #ddd;
  }
}

  .__table>thead>tr>th{
    background: #07A0AB;
    color: #ffffff;
    border-bottom: 0px solid #ddd !important;
  }

/*teble end*/

.__tablp{
  letter-spacing: -0.713086px;
  color: #9c9c9c;
  /* color: red; */
  font-family: SourceSansPro-Regular;
}

.__tablp1{
  letter-spacing: -0.713086px;
  color: #00CCBB;
  font-family: SourceSansPro-Regular;
}


.__tabimg{
  width: 15px;
}

.__tabimg1{
  width: 8px;
}

.__tabimg2{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.__allstatus{
  border-radius: 10px;
  text-align: center;
  padding: 2px 8px;
  cursor: default;
  width: 85px;
}

.__allstatus:active {
    box-shadow: none !important;
}



.__datanxt{
  color: #B5B5B5;
  letter-spacing: -0.713086px;
}

.__datanxta{
  color: #F87501;
  letter-spacing: -0.713086px;
}

.hidden1 {
  display:none;
}

.hidden2 {
  display:none;
}

.__kangprog{
  background: #EFEFEF;
  border-radius: 50px;
  padding: 0px 7px;
  margin: 0 5px;
}

.__kangproga{
  background: #0C9EA9;
  border-radius: 50px;
  padding: 0px 7px;
  margin: 0 5px;
}

.__regdivform{
  border: 1px solid transparent;
  border-radius: 0 !important;
  border-bottom: 1px solid #00000033;
  height: 40px;
  box-shadow: none !important;
}

.__regdivform:focus{
  border: 1px solid transparent !important;
  border-bottom: 1px solid #00000033 !important;
  box-shadow: none !important;
}

.__modseps{
  color: #001b3647;
  font-family: SourceSansPro-Regular;
}

.__modseps1{
  font-size: 12px;
  color: #07A0AB;
  font-family: SourceSansPro-Bold;
  margin-top: -10px;
}

.__modlab{
  font-size: 12px;
  color: #07A0AB;
}

.__autbtn{
  padding: 7px;
  letter-spacing: 0.3px;
  color: #FFFFFF !important;
  background: #0C9EA9;
  border: 1px solid #0C9EA9;
  border-radius: 5px;
  font-family: SourceSansPro-Bold;
}

.__autbtn1{
  padding: 7px;
  letter-spacing: 0.3px;

  color: #0C9EA9 !important;
  background: #ffffff;
  border: 1px solid #0C9EA9;
  border-radius: 5px;
  font-family: SourceSansPro-Bold;
  outline: none !important;
}


.__dnbtn{
  padding: 7px 15px;
  letter-spacing: 0.3px;

  color: #F87501 !important;
  background: #ffffff;
  border: 1px solid #F87501;
  border-radius: 5px;
  font-family: SourceSansPro-Bold;
  outline: none !important;
}

.__dnbtn1{
  letter-spacing: 0.3px;
    color: #0C9EA9 !important;
    font-family: SourceSansPro-Bold;
    background: #ffffff;
    border: 1px solid #0C9EA9;
    border-radius: 5px;
    padding: 7px 18px;
    outline: none !important;
    margin-top: 2rem;
}

.__dnbtnp{
  letter-spacing: 0.3px;
    font-family: SourceSansPro-Bold;
    color: #AFAFAF !important;
  background: #ffffff;
  border: 1px solid #AFAFAF;
    border-radius: 5px;
    padding: 7px 18px;
    outline: none !important;
    margin-top: 2rem;
}


  /*upload files*/
.__it .btn-orange
{
    background-color: #ffffff;
    border-color: #ccc!important;
    color: #777;
    border: 1px dashed #C4C4C4;
    border-radius: 10px;
    width: 100%;
    padding: 110px 0;
}
.__it input.form-control
{
    height: 54px;
    border:none;
  margin-bottom:0px;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
    box-shadow: none;
}
.__it .form-control:focus
{
    border-color: #ff4d0d;
    box-shadow: none;
    outline: none;
}

.__upl{
  color: #BCBCBC;
}

.__upl span{
  color: #0C9EA9;
  font-family: SourceSansPro-Bold;
}


.fileUpload {
    position: relative;
    overflow: hidden;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 131px 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 100%;
}
.__it .btn-new, .__it .btn-next
{
    margin: 10px 0px;
    border-radius: 3px;
    background-color: transparent;
    border-color: #2a94d5;
    color: #2a94d5;
    font-size: 16px;
    width: 155px;
}
.__it .btn-next
{
    background-color: #2a94d5;
    color: #fff;
}
.__it .btn-check
{
  cursor:pointer;
  line-height:54px;
  color:red;
}

.__it .btn-orange img {
    width: 50px;
}

.__it #uploader .docErr
{
    position: absolute;
    right:auto;
    left: 10px;
    top: -56px;
    padding: 10px;
    font-size: 15px;
    background-color: #fff;
    color: red;
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.2);
    display: none;
}
.__it #uploader .docErr:after
{
    content: '\f0d7';
    display: inline-block;
    font-family: FontAwesome;
    font-size: 50px;
    color: #fff;
    position: absolute;
    left: 30px;
    bottom: -40px;
    text-shadow: 0px 3px 6px rgba(0,0,0,0.2);
}

/*upload files end*/

.__chcdiv{
  border: 1px solid #c4c4c44d;
  border-radius: 5px;
  padding: 10px;
}

.__allsel{
  color: #07A3AE;
  border: 1px solid #07A3AE;
  padding: 6px 4px;
  margin-left: -86px;
}

.__allsel:focus{
  border: 1px solid #07A3AE !important;
  box-shadow: none !important;
}


/*TC*/
.__checktc{
  color: #A5A5A5 !important;
  font-family: SourceSansPro-Bold;
}

.__checktc .__sp{
  color: #F87501 !important;
}

.__checktc input:checked ~ .checkmark {
    background-color: #F87501 !important;
}
/*TC*/

/* The check */
.check {
  display: block;
  position: relative;
  padding-left: 16px;
  margin-bottom: 12px;
  padding-right: 0;
  cursor: pointer;
  color: #414141;
line-height: 1.57;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 100;
}

/* Hide the browser's default checkbox */
.check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
   position: absolute;
  top: 3px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent; ;
  border: 1px solid #C4C4C4;
  border-radius:3px;
}



/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
    background-color: #07A0AB  ;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
    display: block;
    border-color: #ffffff;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
    left: 3px;
    top: 1px;
    width: 4px;
    height: 7px;
    border: solid ;
    border-color:#000000;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*overview css end*/

/*select2 css*/

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #c4c4c44d !important;
}


.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #07A0AB !important;
    letter-spacing: -0.65152px;
    color: #ffffff;
    border: 1px solid rgba(248, 117, 1, 0.1) !important;
}

.select2-selection__choice .badge {
    color: #000;
    background-color: #fff;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #ffffff !important;
    font-size: 20px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 0px 0 6px !important;
}

.select2-container .select2-search--inline .select2-search__field{
    padding: 0 5px !important;
}
/*select2 css end*/


/*modal css*/
.__modal-dialog {
  max-width: 764px;
}

.__close{
  font-size: 20px;
  font-weight: 100;
  line-height: 0.4;
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 99;
}

.__yosuccimg{
  width: 70px;
}

.__yosucc{
  font-size: 16px;
  line-height: 147.4%;
  color: #BCBCBC;
  font-family: SourceSansPro-Regular;
  margin-top: 20px;
}

.__yosucc1{
  font-size: 16px;
  line-height: 147.4%;
  color: #AFAFAF;
  font-style: italic;
  font-family: SourceSansPro-ExtraBold;
  margin-top: 20px;
}
/*modal css end*/



/*submission css*/
.__nav-tabsub{
  background: #29AAB4;
  border-radius: 30px;
}

.__nav-tabsub>li>a {
    color: #ffffff;
    padding: 6px 25.4px;
}

.__nav-tabsub>li>a:hover {
    border-color: transparent;
}

.__nav-tabsub>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #29AAB4;
    background: #FAFAFA;
    box-shadow: 0px 10px 15px rgba(12, 158, 169, 0.2);
    border-radius: 30px;
    margin-top: -1px;
}
/*submission css end*/

/*profile css*/

.__moduldiv {
  border: 1.499px dashed #7979794d;
  border-radius: 7.49501px;
  padding: 20px;
}

.__editpic {
  color: #3b3b3bb3;
  margin-top: 10px;
}


/*image upload*/

#picture_preview{
  height: 99px;
  width: 100%;
  border: 1px solid #ccc;
  transition: all 1s ease-in;
}

.__picture input {
  cursor: pointer;
  position: absolute;
  display: block;
  margin-top: -104px;
    opacity: 0;
    height: 100px;
    width: 100%;
    z-index: 9999;
}


#picture_preview1{
  height: 90px;
    width: 90px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    transition: all 1s ease-in;
    margin-left: 0;
    margin-top: 25px;
}

.__picture1 input {
  cursor: pointer;
  position: absolute;
  display: block;
  margin-top: -90px;
  margin-left: 102px;
  opacity: 0;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  z-index: 9999;
}

.__editpic{
  color: #3b3b3bb3;
  margin-top: 10px;
}


/*image upload end*/

.__profip{
  color: #FFFFFF;
  font-family: BentonSans-Bold;
  background: #407BFF;
  border-radius: 50%;
    padding: 20px 0;
    width: 63px;
    height: 63px;
    margin: 15px auto;
}

.__profip1 {
  color: #3b3b3bb3;
}

.__profp{
  color: #3b3b3bb3;
  border: 0.5px solid #0000001a;
  border-radius: 2px;
  padding: 10px;
  margin-top: 20px;
}

.__profbtn{
  letter-spacing: 0.3px;
  color: #FFFFFF !important;
  font-family: SourceSansPro-Bold;
  background: #0C9EA9;
  border: 1px solid #0C9EA9;
  border-radius: 5px;
  padding: 7px 18px;
  outline: none !important;
}

.__proed{
  color: #07A0AB;
  font-family: SourceSansPro-Bold;
}

.__proed1{
  color: #001b36;
}

.__proed2{
  font-size: 13px;
  letter-spacing: -0.65152px;
  color: #F87501;
  background: #FEF1E6;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 100;
  margin-top: 5px;
}

.__proedfil{
  background-color: #ffffff;
  border-color: #ccc!important;
  color: #777;
  border: 1px dashed #C4C4C4;
  border-radius: 10px;
  width: 100%;
  padding: 110px 0;
}

/*profile css end*/


.__viewown{
  letter-spacing: -0.713086px;
  color: #AFAFAF;
  text-align: right;
  margin-right: -17px;
}

.__viewown1{
  letter-spacing: -0.713086px;
  color: #0C9EA9;
  text-align: right;
  margin-right: -17px;
  margin-top: -10px;
}

.__viewimg{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.__seleimgdiv{
  position: absolute;
  /* top: 200px;
  left: 33%; */
  bottom: 4%;
  right: 14%;
  z-index: 999;
}

/*notification css*/
.__notpg{
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.__notpg1{
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: -6px;
}

.__acno{
  color: #0C9EA9;
  font-size: 20px;
}

.__acno1{
  color: #DDDFDB;
  font-size: 20px;
}
/*notification css end*/























/*=================================================================
  @media screen 1203px
==================================================================*/


@media screen and (max-width: 1203px){



}




/*=================================================================
  @media screen 1200px
==================================================================*/


@media screen and (max-width: 1200px){

  .__nav-tabsub>li>a {
    padding: 6px 18.6px;
  }

  .__allsel {
    margin-left: -79px;
  }

  .__dashboard {
    padding: 15px 10px;
  }

  .activedashboard {
    padding: 15px 10px;
  }


  .__dasp1 {
    font-size: 30px;
  }


}



/*=================================================================
  @media screen 1199px
==================================================================*/


@media screen and (max-width: 1199px){





}


/*=================================================================
  @media screen 1024px
==================================================================*/


@media screen and (max-width: 1024px){


  .__sidimg {
    width: 122px;
  }

  .__dashboard {
    padding: 15px 10px;
    font-size: 13px;
  }

  .activedashboard {
    padding: 15px 10px;
    font-size: 13px;
  }

  .__dascard {
    padding: 20px 0 25px;
  }

  .__dasp {
    font-size: 15px;
  }

  .__dasp1 {
    font-size: 25px;
  }

  .__nav-tabsub>li>a {
    padding: 6px 18.9px;
  }

  .__allsel {
    margin-left: -35px;
  }



}



@media screen and (max-width: 992px) {


}



/*=================================================================
  @media screen 991px
==================================================================*/


@media screen and (max-width: 991px){


  .leftbar{
    transform: translate(-300px);
    background-color: #ffffff;
  }
  #openSidebar{
    display: block !important;
    cursor: pointer !important;
  }
  img#closebtn {
     display: block;
  }

  .__dashnavbar {
    position: unset;
    width: 100%;
  }






}


/*=================================================================
  @media screen 800px
==================================================================*/

@media screen and (max-width: 800px){

  .leftbar{
    transform: translate(-300px);
    background-color: #ffffff;
  }
  #openSidebar{
    display: block !important;
    cursor: pointer !important;
  }
  img#closebtn {
     display: block;
  }

  .biger {
    width: 28%;
  }

  .__sidep {
    margin-top: 0%;
  }



  .__house {
    /*width: 100% !important;*/
    padding:0;
  }

  .__dashlogoimg {
    margin-bottom: 0%;
  }

  .__sidimg {
    width: 150px;
  }

  .activedashboard {
    font-size: 16px;
  }

  .__dashboard {
    font-size: 16px;
  }


  .__dasp {
    font-size: 11px;
  }

  .__dasp1 {
    font-size: 25px;
    margin-top: 10px;
  }

  .__dascard {
    padding: 20px 0;
  }

  .__modal-dialog {
    max-width: auto;
  }

  .__nav-tabsub>li>a {
    padding: 6px 16.8px;
  }

  .__allsel {
    margin-left: 0px;
  }


}




@media screen and (max-width: 568px) {

  .biger{
    width: 67% ;
    transform: translate(0px) !important;
    z-index:1  !important;

  }

  .leftbar{
    transform: translate(-1000px);
    background-color: #ffffff;
  }
  #openSidebar{
    display: block !important;
    cursor: pointer !important;
  }
  i#closebtn {
     display: block;
  }

  .maianer {
      padding: 10px 20px;
  }

  .__flexsmail{
    display: flex;
    flex-direction: column;
  }

  .__mobidown{
    order: 2;
  }

  .__mobiup{
    order: 1;
  }



  .__house {
    width: 100% !important;
  }


  .__dasp {
    font-size: 20px;
  }

  .activedashboard {
    font-size: 20px;
  }

  .__dashboard {
    font-size: 20px;
  }

  .__sidimg {
    width: 172px;
  }

  .__modal-dialog {
    max-width: auto;
  }

  .fileUpload input.upload {
    padding: 80px 0;
  }

  .__it .btn-orange {
    padding: 60px 0;
  }


  .__nav-tabsub {
    margin-bottom:10px;
  }

  .__nav-tabsub>li>a {
    padding: 6px 12.4px;
  }

  .__allsel {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .__dnbtn1, .__dnbtnp {
    margin-top: 10px;
  }

  .__subp1, .__subp2 {
    text-align: left;
  }

  .__seleimgdiv {
    top: -18px;
  }




  .__dasovp{
    display: none;
  }

}