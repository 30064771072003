.__appbgc {
  background: #0C9EA9;
  padding: 7% 0 0;
}

.__aboup-alt{
  font-size: 28px;
}

.table-link{
  font-size: 17px;
  color: #0c9ea9 !important;
}

.table tbody > tr > td {
  font-size: 17px;
}

.__latimgp {
  text-transform: capitalize;
  /* width: 320px; */
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.increaseHeight {
  height: 130px;
}

.increaseHeight p, .increaseHeight span{
  font-family: SourceSansPro-Regular !important;
}

/* .increaseHeight{
  font-family: 
} */

.padding-top{
  padding-top: 14.4px !important;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: 0 !important;
  }
}

#weatherForecast {
  display: flex;
}

#weatherForecast .__weathercard {
  margin-right: 1.25rem;
}