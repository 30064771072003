.page {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	/* cursor: pointer; */
}

.page-card {
	width: 32%;
	/* width: calc(calc(100% / 3) - 10px);
	height: 280px;
	min-width: 290px; */
	border: 1px solid #eee;
	border-radius: 10px;
	margin-bottom: 20px;
	cursor: pointer;
}

.page-card img{
	width: 100%;
	height: 11rem;
	border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.__page-p{
	margin: 10px;
}

/* .page-card:not(:last-of-type) {
	margin-right: 10px;
} */






/*=================================================================
  @media screen 2560px
==================================================================*/

@media only screen and (min-width: 2560px){
    .container {
      width: 2000px;
    }

   
  
  
  
  
  
  
  }
  
  
  /*=================================================================
    @media screen 1440px
  ==================================================================*/
  
  
  @media only screen and (min-width: 1440px){
     
    
  
  
  }
  
  
  /*=================================================================
    @media screen 1366px
  ==================================================================*/
  
  
  @media screen and (max-width: 1366px){
    
  }
  
  
  
  /*=================================================================
    @media screen 1220px
  ==================================================================*/
  
  
  @media only screen and (max-width: 1220px){
    
  
  
  
  
  }
  
  
  /*=================================================================
    @media screen 1199px
  ==================================================================*/
  
  
  @media only screen and (max-width: 1199px){
    
  
  
    
  
    
  
  }
  
  
  /*=================================================================
    @media screen 1024px
  ==================================================================*/
  
  
  @media only screen and (max-width: 1024px){
  
  
    
  
  
  
  
    
  
  }
  
  
  /*=================================================================
    @media screen 992px
  ==================================================================*/
  
  
  @media only screen and (max-width: 992px){
      
  }
  
  /*=================================================================
    @media screen 991px
  ==================================================================*/
  
  
  @media only screen and (max-width: 991px){
  
    
  
  
  
  
  }
  
  
  /*=================================================================
    @media screen 800px
  ==================================================================*/
  
  @media only screen and (max-width: 800px){

    
  

  
  
  }
  
  /*=================================================================
    @media screen 767px
  ==================================================================*/
  
  @media only screen and (max-width: 767px){
  
  
    
  }
  
  
  /*=================================================================
    @media screen 560px
  ==================================================================*/
  
  
  @media only screen and (max-width: 560px){
    
	.page-card {
		width: 100%;
	}
  
  
  }
  
  
 