.chip {
  display: inline-flex;
  justify-content: space-between;
  background-color: #0C9EA9;
  color: #fff;
  min-width: 80px;
  align-items: center;
  border-radius: 3px;
  padding: 1px 3px;
  margin: 2px;
}