	.__nehead .navbar-toggle .icon-bar {
		    background: #0C9EA9 !important;
		}

		.__nehead .__navbar{
			background: #FFFFFF;
			box-shadow: 0px 4px 30px rgba(12, 158, 169, 0.15);
		}

		.__nehead .__navlink {
    		color: #0c9ea999 !important;
		}

		.__nehead .__navlink:hover:after {
		    top: 3px;
		    background: #0C9EA9;
		}

		.__nehead .__navlinka {
    		color: #0c9ea999 !important;
		}

		.__nehead .__navlinka:after {
		    height: 2px !important;
		    background: #0C9EA9 !important;
		}

		.__nehead .__navlinkbtn1 {
    		color: #ffffff !important;
		}

		.__nehead .hvr-shutter-in-vertical:before {
    		background: #0C9EA9;
		}

		.__nehead .hvr-shutter-in-vertical:hover{
		    color: #07A0AB !important;
		    border: 1px solid #07A0AB;
		    background:#ffffff !important;
		}